import React from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';

import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import pageNotFoundHook from 'hooks/page-not-found/page-not-found.hook';

import s from './PageNotFound.scss';

export default (): JSX.Element => {
    const pageNotFoundData = () => {
        const data = pageNotFoundHook();

        if (data.length === 0) {
            throw new Error('pageNotFound directus collection does not have any fields.');
        }

        return data[ 0 ].node;
    };

    const { title, sub_title, image, image_alt, body, link, link_text } = pageNotFoundData();

    return (
        <div className={s.content}>
            <div className={s.content__block}>
                <h1 className={s.title}>{title}</h1>
                <h3 className={s.subTitle}>{sub_title}</h3>
                <Markdown className={s.body} source={body} container />

                <Link to={link} className={s.link}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={s.link__icon} />
                    {link_text}
                </Link>
            </div>

            <div className={s.content__block}>
                <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    className={s.img}
                    alt={image_alt}
                    fadeIn
                />
            </div>
        </div>
    );
};
