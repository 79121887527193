import React, { CSSProperties } from 'react';

import { Props } from './Section.interface';

import s from './Section.scss';

export default (props: Props): JSX.Element => {
    const { className, top, bottom, children, colour, isHome } = props;
    const styles = {
        home: isHome ? s.section___home : '',
        colour: colour ? s[ `section___${colour}` ] : '',
    };
    const style: CSSProperties = {
        marginTop: top !== undefined ? `${top}px` : '',
        marginBottom: bottom !== undefined ? `${bottom}px` : '',
    };

    return (
        <section
            className={[ s.section, className, styles.colour, styles.home ].join(' ')}
            style={style}
        >
            {children}
        </section>
    );
};
